<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="130px"
    >
      <!-- <el-row>
        <el-col :span="6"> -->
      <el-form-item label="医生姓名：">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="10"> -->
      <el-form-item label="科室：">
        <el-select
          v-model="formInline.parentDeptCode"
          placeholder="请选择科室"
          filterable
        >
          <el-option
            v-for="item in parentDepartmentList"
            :key="item.deptCode"
            :value="item.deptCode"
            :label="item.deptName"
          />
        </el-select>
        <el-select
          v-if="formInline.parentDeptCode !== ''"
          v-model="formInline.deptCode"
          placeholder=""
          filterable
        >
          <el-option
            v-for="item in departmentList"
            :key="item.deptCode"
            :label="item.deptName"
            :value="item.deptCode"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <el-form-item label="认证状态：">
        <el-select v-model="formInline.authenticationStatus">
          <el-option
            v-for="item in authenticationStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
      </el-row>
      <el-row> -->
      <!-- <el-col :span="6"> -->
      <el-form-item label="上/下线：">
        <el-select v-model="formInline.onlineStatus" filterable>
          <el-option
            v-for="item in onlineStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="10"> -->
      <el-form-item label="账户状态：">
        <el-select v-model="formInline.freezeStatus" filterable>
          <el-option
            v-for="item in accountStatusList"
            :key="item.text"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="同步医网信状态：">
        <el-select v-model="formInline.trustStatus" filterable>
          <el-option
            v-for="item in trustStatusList"
            :key="item.text"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="医生团队：">
        <el-input
          v-model.trim="formInline.teamName"
          v-check-permission
          placeholder="请输入"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('ADDDOCTOR', $route)"
          type="primary"
          @click="addData(null, 0)"
        >
          新增
        </el-button>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
    </el-form>
    <el-table ref="multipleTable" :data="orderListData" border class="width-96">
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="id" label="医生id" width="150" align="center" />
      <el-table-column
        prop="teamName"
        label="医生团队"
        width="180"
        align="center"
      />
      <el-table-column prop="doctorName" label="姓名" align="center" />
      <el-table-column
        prop="professionalTitleName"
        label="职称"
        align="center"
      />
      <el-table-column prop="deptName" label="医院科室" align="center" />
      <el-table-column prop="mobile" label="联系方式" align="center" />
      <!-- <el-table-column
        prop="mobile"
        label="同步监管部门状态"
        align="center"
      /> -->
      <el-table-column prop="trustStatus" label="同步医网信状态" align="center">
        <template slot-scope="scope">
          <span>{{ trustStatusDic[scope.row.trustStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="authenticationStatus"
        label="认证状态"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ statusList[scope.row.authenticationStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="onlineStatus" label="上/下线状态" align="center">
        <template slot-scope="scope">
          {{
            scope.row.authenticationStatus
              ? scope.row.onlineStatus
                ? '上线'
                : '下线'
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column prop="freezeStatus" label="账户状态" align="center">
        <template slot-scope="scope">
          <span>{{ !scope.row.freezeStatus ? '正常' : '冻结' }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="350" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="$checkPermission('DOCTOREDIT', $route)"
            type="text"
            size="mini"
            @click="addData(scope.row, 1)"
          >
            编辑
          </el-button>
          <el-button
            v-if="
              scope.row.authenticationStatus === 'IN_AUTH' &&
              $checkPermission('DOCTORADUIT', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            审核
          </el-button>
          <el-button
            v-if="
              !scope.row.freezeStatus &&
              $checkPermission('DOCTORFREEZE', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 3)"
          >
            冻结
          </el-button>
          <el-button
            v-if="
              scope.row.authenticationStatus === 'AUTH_SUCCESS' &&
              !scope.row.freezeStatus &&
              !scope.row.onlineStatus &&
              $checkPermission('DOCTORONLINE', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 4)"
          >
            上线
          </el-button>
          <el-button
            v-if="
              scope.row.authenticationStatus === 'AUTH_SUCCESS' &&
              !scope.row.freezeStatus &&
              scope.row.onlineStatus &&
              $checkPermission('DOCTOROFFLINE', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 5)"
          >
            下线
          </el-button>
          <el-button
            v-if="
              scope.row.freezeStatus && $checkPermission('UNFREEZE', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 6)"
          >
            解冻
          </el-button>
          <el-button
            v-if="
              scope.row.authenticationStatus === 'AUTH_SUCCESS' &&
              $checkPermission('SUPERVISION', $route)
            "
            type="text"
            size="mini"
            :disabled="scope.row.teamCode === 'MN02'"
            @click="dataOperation(scope.row, 7)"
          >
            同步监管部门
          </el-button>
          <el-button
            v-if="
              scope.row.authenticationStatus === 'AUTH_SUCCESS' &&
              $checkPermission('DOCTORINFO', $route)
            "
            type="text"
            size="mini"
            :disabled="scope.row.teamCode === 'MN02'"
            @click="dataOperation(scope.row, 8)"
          >
            同步医网信
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <tips
      v-if="serviceDialogVisible"
      :detail-data="detailData"
      :title-type="titleType"
      :visible="serviceDialogVisible"
      @cancel="serviceDialogVisible = false"
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import moment from 'moment';
import {
  accountStatusList,
  authenticationStatusList,
  onlineStatusList,
  trustStatusList,
  trustStatusDic,
} from '@/utils/component/publicList';
import Tips from './component/tips.vue';
export default {
  name: 'ServiceManageList',
  components: {
    Tips,
  },
  data() {
    this.statusList = {
      AUTH_SUCCESS: '认证成功',
      AUTH_FAIL: '认证失败',
      IN_AUTH: '认证中',
      WAIT_AUTH: '待认证',
    };
    return {
      titleType: '', // 弹框类型
      detailData: {}, // 父组件传过去的值
      refundDialogVisible: false, // 退诊弹框显示与否
      refundId: '', // 退诊患者id
      parentDepartmentList: [], // 一级科室列表
      departmentList: [], // 二级科室列表
      onlineStatusList: onlineStatusList, // 上下线状态
      accountStatusList: accountStatusList, // 账户状态列表，冻结、正常
      authenticationStatusList: authenticationStatusList, // 认证状态,认证中、认证成功、认证失败
      trustStatusList: trustStatusList, // 同步医网信状态
      trustStatusDic: trustStatusDic, // 同步医网信字典
      serviceDialogVisible: false, // 控制弹框显示与否
      formInline: {
        doctorName: '',
        onlineStatus: 'all',
        parentDeptCode: '',
        freezeStatus: 'all',
        deptCode: '',
        authenticationStatus: 'all',
        trustStatus: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  // test>>>>>>>
  computed: {
    ...mapState('basicDictionary', {
      allDepartmentList: (state) => state.allDepartmentList,
    }),
  },
  watch: {
    'formInline.parentDeptCode': function (value) {
      this.formInline.deptCode = '';
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value && value !== '') {
          this.departmentList = JSON.parse(JSON.stringify(item.children));
          this.departmentList.unshift({
            deptCode: '',
            deptName: '全部',
          });
        }
      });
    },
  },
  mounted() {
    this.getDoctorList();
    this.getDepartment();
  },
  methods: {
    ...mapActions('doctorManage', ['doctorPageQuery', 'online']),
    ...mapActions('basicDictionary', ['deptQueryList']),
    async getDepartment() {
      await this.deptQueryList();
      console.log(this.allDepartmentList, 'allDepartmentList');
      this.listToTree(this.allDepartmentList);
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getDoctorList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getDoctorList();
    },
    // 科室列表转树
    listToTree(oldArr) {
      this.parentDepartmentList = [];
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode
          ? element.parentDeptCode
          : null;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });

      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      this.parentDepartmentList = oldArr;
      this.parentDepartmentList.unshift({
        deptCode: '',
        deptName: '全部',
      });
      console.log(this.parentDepartmentList, '11');
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        onlineStatus: 'all',
        parentDeptCode: '',
        freezeStatus: 'all',
        deptCode: '',
        authenticationStatus: 'all',
        trustStatus: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getDoctorList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getDoctorList();
    },
    // 分页查询用户套餐列表
    getDoctorList() {
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      params.authenticationStatus =
        this.formInline.authenticationStatus === 'all'
          ? ''
          : params.authenticationStatus;
      params.onlineStatus =
        this.formInline.onlineStatus === 'all' ? '' : params.onlineStatus;
      params.freezeStatus =
        this.formInline.freezeStatus === 'all' ? '' : params.freezeStatus;
      this.doctorPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
        })
        .catch(() => {});
    },
    // 解冻、下线、冻结等操作后刷新列表
    refreshList() {
      this.getDoctorList();
    },
    // 上线
    serviceOnline() {
      this.online({ id: this.id }).then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '医生上线成功',
          });
          this.getDoctorList();
        }
      });
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      console.log('打印这个的状态', item, type);
      this.id = item.id;
      this.detailData = item;
      switch (type) {
        case 3:
          this.serviceDialogVisible = true;
          this.titleType = '冻结';
          break;
        case 4:
          this.serviceOnline();
          break;
        case 5:
          this.serviceDialogVisible = true;
          this.titleType = '下线';
          break;
        case 6:
          this.serviceDialogVisible = true;
          this.titleType = '解冻';
          break;
        default:
          this.$router.push({
            path: '/doctorManage/doctorDetail',
            query: {
              id: item.id,
              type: Number(type),
            },
          });
      }
    },
    // 新增医生
    addData(item, type) {
      this.$router.push({
        path: '/doctorManage/addDoctor',
        query: {
          id: !item ? '' : item.id,
          type: Number(type),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
